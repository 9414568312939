import type { AxiosRequestConfig, AxiosError } from 'axios'
import type { BaseQueryFn } from '@reduxjs/toolkit/query'
import axios from 'axios'
import Cookies from 'js-cookie'
import { isServerSide } from '../utils'

export const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: '' }
    ): BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig['method']
            data?: AxiosRequestConfig['data']
            params?: AxiosRequestConfig['params']
            extraData?: any
        },
        unknown,
        unknown
    > =>
        async ({ url, method, data, params }) => {
            const headers: Record<string, string> = {
                'X-Timezone-Offset': String(-new Date().getTimezoneOffset()),
                'X-Auth-Key' : Cookies.get('auth-token'),
            }

            // Axios needs 'Accept-Encoding' header, but browser already handles it,
            // so add it manually only for server
            if (isServerSide()) {
                headers['Accept-Encoding'] = 'gzip,deflate,compress'
            }

            try {
                const result = await axios({
                    url: baseUrl + url,
                    method,
                    data,
                    params,
                    headers,
                })
                return { data: result.data }
            } catch (axiosError) {
                const err = axiosError as AxiosError
                return {
                    error: {
                        status: err.response?.status,
                        data: err.response?.data || err.message,
                    },
                }
            }
        }
