import '../styles/globals.css'
import { Provider, useDispatch } from 'react-redux'
import { wrapper } from '../store'
import '../styles/main.scss'
import { ReactElement, ReactNode, useMemo, useEffect } from 'react'
import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import Layout from '../components/Layout'
import '../styles/reset.css'
import { appWithTranslation } from 'next-i18next'
import nextI18NextConfig from '../next-i18next.config.js'

import 'react-calendar/dist/Calendar.css'
import '../styles/calendar.css'

import 'moment/locale/ru'
import 'moment/locale/fr'
import 'moment/locale/de'
import { getFavorites, setFavorites } from '../features/app'
import Cookies from 'js-cookie'
import {EventProvider, ScreenProvider} from '../components/context';
import {useRoutePrevious} from '../hooks';
import { RouteProgressBar } from '../components/RouteProgressBar'
import Head from 'next/head'
import { useRouter } from 'next/router'

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
    const {store} = wrapper.useWrappedStore({
        initialState: pageProps.initialState,
    })

    /** Disable Link prefetch globally: on first show in viewport and on hover.
     * See how it works in next.js: https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#2-prefetching.
     * In our case there's no need in pages prefetch, as we don't use SSR so much.
     * */
    const router = useRouter()
    useMemo(() => {
        router.prefetch = async () => { }
    }, [router])

    const getLayout = Component.getLayout ?? ((page) => page)

    if (Component.getLayout) {
        return getLayout(
            <Provider store={store}>
                <Component {...pageProps} />
            </Provider>
        )
    }

    return (
        <Provider store={store}>
            <Head>
                <meta
                    data-n-head='ssr'
                    name='viewport'
                    content='viewport-fit=cover, width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no'
                />
            </Head>
            <RouteProgressBar />
            <LogicController />
            <Favourites />
            <Layout
                isBlockedCountry={pageProps.countryCode === 'NL'}
                hideFooter={pageProps.hideFooter}
            >
                <EventProvider>
                    <ScreenProvider>
                        <Component {...pageProps} />
                    </ScreenProvider>
                </EventProvider>
            </Layout>
        </Provider>
    )
}

function LogicController(): null {
    useRoutePrevious()
    return null
}

function Favourites(): null {
    const dispatch = useDispatch()

    useEffect(() => {
        const token = Cookies.get('auth-token')

        if (token) {
            // @ts-ignore
            dispatch(getFavorites())
            // save to localstorage updated data
        } else {
            // get structure from localstorage
            try {
                const favorites = localStorage.getItem('favorites')
                const parsed = JSON.parse(favorites)

                // set to store
                if (parsed) {
                    dispatch(setFavorites(parsed))
                } else {
                    // set default structure
                    dispatch(
                        // @ts-ignore
                        setFavorites({
                            team: [],
                            match: [],
                            competition: [],
                            bookmaker: [],
                        })
                    )
                }
            } catch (e) { }
        }
    }, [])

    return null
}

export default appWithTranslation(App as any, nextI18NextConfig as any)
