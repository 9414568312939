import { createReducer } from '@reduxjs/toolkit'
import { postLogin, postLogout, postRegister, setCookies } from './actions'
import Cookies from 'js-cookie'
type StatusAction = 'idle' | 'loading' | 'succeeded' | 'failed'

export type AuthState = {
    user: any
    token: string
    error: string | null
    isRegisterSuccess: boolean
    status: {
        signin: StatusAction
        signup: StatusAction
        logout: StatusAction
    }
}

interface User {
    // Определите поля пользователя
    id: string
    login: string
    avatar_id: string
    odds_type: string
    cookies: string
    timezone_id: string
    status: string
}

function loadUserFromLocalStorage(): User | null {
    if (typeof window == 'undefined') return null
    try {
        const userRaw = localStorage.getItem('user')
        if (!userRaw) return null

        const user = JSON.parse(userRaw)

        // old version
        if (user.avatar_id) {
            // clear localstorage
            localStorage.setItem('user', null)
            return null
        }

        return user
    } catch (error) {
        console.error('Error loading user from localStorage:', error)
    }
    return null
}

function loadTokenFromCookies(): string {
    if (typeof window == 'undefined') return null
    try {
        const serializedToken = Cookies.get('auth-token')
        if (serializedToken) {
            return serializedToken
        }
    } catch (error) {
        console.error('Error loading user from localStorage:', error)
    }
    return null
}

const initialState: AuthState = {
    user: loadUserFromLocalStorage(),
    token: loadTokenFromCookies(),
    error: null,
    isRegisterSuccess: false,
    status: {
        signin: 'idle',
        signup: 'idle',
        logout: 'idle',
    },
}

export const authReducer = createReducer(initialState, (builder) =>
    builder
        .addCase(setCookies.type, (state, action: any) => {
            // "mutate" the array by calling push()
            // @ts-ignore
            state.cookies = action?.payload as any
        })
        .addCase(postLogin.pending, (state, action) => {
            state.status.signin = 'loading'
        })
        .addCase(postLogin.fulfilled, (state, action) => {
            if (action.payload.status == 'ok') {
                state.status.signin = 'succeeded'
                state.user = action.payload.data.user
                state.token = action.payload.data['X-Auth-Key']
                // setcookies
                Cookies.set('auth-token', action.payload.data['X-Auth-Key'], { expires: 365 })
                // set localstorage data user
                localStorage.setItem(
                    'user',
                    JSON.stringify(action.payload.data.user)
                )
            } else {
                state.status.signin = 'failed'
                state.error = action?.payload?.data?.error
            }
        })
        .addCase(postLogin.rejected, (state, action: any) => {
            state.status.signin = 'failed'
            console.log('action', action);
            state.error = action?.payload?.data?.error
        })
        .addCase(postRegister.pending, (state, action) => {
            state.status.signup = 'loading'
        })
        .addCase(postRegister.fulfilled, (state, action) => {
            if (action.payload.status == 'ok') {
                state.status.signup = 'succeeded'
                state.user = action.payload.data.user
                state.token = action.payload.data['X-Auth-Key']
                state.isRegisterSuccess = true
                // setcookies
                Cookies.set('auth-token', action.payload.data['X-Auth-Key'], { expires: 365 })
                // set localstorage data user
                localStorage.setItem(
                    'user',
                    JSON.stringify(action.payload.data.user)
                )
            } else {
                state.status.signin = 'failed'
                state.error = action.payload.data.error
            }
        })
        .addCase(postRegister.rejected, (state, action) => {
            state.status.signup = 'failed'
            state.error = 'failed'
        })
        .addCase(postLogout.pending, (state, action) => {
            state.status.logout = 'loading'
        })
        .addCase(postLogout.fulfilled, (state, action) => {
            if (action.payload.status == 'ok') {
                // remove all user data
                state.user = null
                state.token = null
                localStorage.removeItem('user')
                Cookies.remove('auth-token')
            } else {
                state.status.logout = 'failed'
                state.error = action.payload.data.error
            }
        })
        .addCase(postLogout.rejected, (state, action) => {
            state.status.logout = 'failed'
            state.error = 'failed'
        })
)

export default authReducer
